import React from 'react';

export default function PageContent({data}) {
    const {page} = data;
    const {title, content} = page;

    return (
        <div className={'page-content'}>
            <div className="content-wrapper">
                <h1 dangerouslySetInnerHTML={{__html: title}}/>
                <div className={'content'}
                     dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </div>
    );
}
