import React from "react"
import {graphql} from "gatsby"
import Layout from '../../components/layout';
import PageContent from '../../components/PageContent';
import SEO from "../../components/SEO";

export default function SingleArticle({data, pageContext}) {
  return (
    <>
      <SEO seoData={data.page.seo}/>
      <Layout language={pageContext.language}>
        <PageContent data={data}/>
      </Layout>
    </>
  )
}

export const query = graphql`
    query article($id: String!) {
        page: wpArticle(id: { eq: $id }) {
            date
            content
            title
            uri
            featuredImage {
                node {
                    sourceUrl
                }
            }
            seo {
                metaDesc
                metaKeywords
                metaRobotsNoindex
                metaRobotsNofollow
                title
                opengraphSiteName
                opengraphDescription
                opengraphImage {
                    sourceUrl
                }
            }
        }
    }
`;
